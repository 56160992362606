<template>
  <div id="AlertOverviewContainer" class="mt-3 ms-3" v-if="alerts">
    <add-alert-modal :alertScope="Constants.ALERT_SCOPE_ENVIRONMENT" :onClose="modalClosed" />

    <div class="row mb-5">
      <div class="col-sm-12">
        <h3>{{ $t("alertOverview.pageTitle") }}</h3>
        <small>{{ $t("general.environment") }}: {{ environmentName }}</small>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-sm-12">
        <h4>
          {{ $t("general.environment") }}
          <span class="ms-1 me-2">
            <font-awesome-icon v-title="$t('alertOverview.environmentInfo')" title-placement="right" icon="info-circle"
              class="fa-small" />
          </span>
        </h4>
      </div>
      <div class="col-sm-12">
        <el-table lazy :data="environmentAlerts" :default-sort="{ prop: 'createdOn', order: 'descending' }"
          style="width: 100%">
          <div slot="empty">{{ $t("notifications.noAlertsConfigured") }}</div>
          <el-table-column prop="alertType" :label="'Typ'">
            <template slot-scope="scope">
              <div v-if="scope.row.alertType === Constants.ALERT_TYPE_EMAIL">
                <font-awesome-icon icon="envelope" v-title="scope.row.alertType" />
              </div>
              <div v-else>
                {{ scope.row.alertType }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="alertResultType" :label="$t('flow.alertCondition')">
            <template slot-scope="scope">
              <div v-if="scope.row.alertResultType === Constants.FLOW_RUN_SUCCEEDED
                ">
                <span class="resultIcon">
                  <font-awesome-icon icon="check-circle" />
                </span>
              </div>
              <div v-else>
                <span class="resultIcon">
                  <font-awesome-icon icon="times-circle" />
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="alertTarget" :label="'An'">
          </el-table-column>
          <el-table-column prop="createdOn" sortable :label="$t('flow.alertCreatedOn')">
            <template slot-scope="scope">
              {{ scope.row.createdOn | date }}
            </template>
          </el-table-column>
          <el-table-column width="80">
            <template slot="header">
              <span class="clickable" @click="addAlert()" v-title="$t('flow.addAlert')">
                <font-awesome-icon icon="plus" />
              </span>
            </template>
            <template slot-scope="scope">
              <span class="clickable" @click="deleteAlert(scope.row.id)" v-title="$t('flow.deleteAlert')">
                <font-awesome-icon icon="trash-alt" />
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="row mt-5 mb-5" v-for="group in groupedAlerts" :key="group.flowId">
      <div class="col-sm-12">
        <h4 class="link-hover" @click="onFlowSelected(group[0].flowId)">Flow: {{ getFlowName(group[0].flowId) }}</h4>
      </div>
      <div class="col-sm-12">
        <el-table lazy :data="group" :default-sort="{ prop: 'createdOn', order: 'descending' }" style="width: 100%">
          <div slot="empty">{{ $t("notifications.noAlertsConfigured") }}</div>
          <el-table-column prop="alertType" :label="'Typ'">
            <template slot-scope="scope">
              <div v-if="scope.row.alertType === Constants.ALERT_TYPE_EMAIL">
                <font-awesome-icon icon="envelope" v-title="scope.row.alertType" />
              </div>
              <div v-else>
                {{ scope.row.alertType }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="alertResultType" :label="$t('flow.alertCondition')">
            <template slot-scope="scope">
              <div v-if="scope.row.alertResultType === Constants.FLOW_RUN_SUCCEEDED
                ">
                <span class="resultIcon">
                  <font-awesome-icon icon="check-circle" />
                </span>
              </div>
              <div v-else>
                <span class="resultIcon">
                  <font-awesome-icon icon="times-circle" />
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="alertTarget" :label="'An'">
          </el-table-column>
          <el-table-column prop="createdOn" sortable :label="$t('flow.alertCreatedOn')">
            <template slot-scope="scope">
              {{ scope.row.createdOn | date }}
            </template>
          </el-table-column>
          <el-table-column width="80">

          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import AddAlertModal from './AddAlert.vue';
import { Constants } from '../shared/constants';
import { GET_ENVIRONMENT_ALERTS_REQUEST, DELETE_ENVIRONMENT_ALERT_REQUEST } from '../store/actions/alert';

export default {
  name: 'AlertOverview',
  components: {
    AddAlertModal,
  },
  created() {
    if (this.$route.params.custId && this.$route.params.envId) {
      this.loadAlerts();
    }
  },
  data() {
    return {
      refreshInProgress: false,
      Constants,
    };
  },
  computed: {
    environmentName() {
      const array = this.$store.state.customer.environments;
      if (array == null) return array;

      return array.filter((env) => env.id === this.$route.params.envId)[0]
        .displayName;
    },
    alerts() {
      return this.$store.state.alert.alerts;
    },
    flowAlerts() {
      return this.alerts.filter((alert) => alert.alertScope === Constants.ALERT_SCOPE_FLOW);
    },
    groupedAlerts() {
      const groupByKey = (list, key) => list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});
      return groupByKey(this.flowAlerts, 'flowId');
    },
    environmentAlerts() {
      return this.alerts.filter((alert) => alert.alertScope === Constants.ALERT_SCOPE_ENVIRONMENT);
    },
  },
  methods: {
    loadAlerts() {
      if (this.$route.params.custId && this.$route.params.envId) {
        this.$store.dispatch(GET_ENVIRONMENT_ALERTS_REQUEST, {
          customerId: this.$route.params.custId,
          environmentId: this.$route.params.envId,
        })
          .catch(() => {
            this.$toasted.error(this.$t('notifications.loadAlertsError'), { duration: 2400 });
          });
      }
    },
    addAlert() {
      this.$modal.show('add-alert');
      this.loadAlerts();
    },
    deleteAlert(alertId) {
      if (this.$route.params.custId && this.$route.params.envId) {
        this.$store.dispatch(DELETE_ENVIRONMENT_ALERT_REQUEST, {
          customerId: this.$route.params.custId,
          environmentId: this.$route.params.envId,
          alertId,
        })
          .then(() => {
            this.loadAlerts();
            this.$toasted.success(this.$t('notifications.deleteAlertSuccess'), { duration: 1200 });
          })
          .catch(() => {
            this.$toasted.error(this.$t('notifications.deleteAlertError'), { duration: 2400 });
          });
      }
    },
    modalClosed() {
      this.loadAlerts();
    },
    onFlowSelected(flowId) {
      this.$router.push({ name: 'ListFlowRuns', params: { envId: this.$route.params.envId, flowId } }).catch(() => { });
    },
    getFlowName(flowId) {
      return this.$store.state.flow.flows?.find((x) => x.id === flowId)?.name ?? this.$t('general.unknown');
    },
  },
};
</script>

<style lang="scss" scoped></style>
