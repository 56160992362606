<template>
  <div class="sidebar">
    <div class="row" v-if="flows && flows.length >= 0">
      <aside class="col-md-2 col-sm-12 p-2 text-light shadow">
        <perfect-scrollbar>
          <div class="dashboard-home d-inline" @click="onHomeSelected()">
            {{ $t("general.dashboard") }}
          </div>
          <span class="refreshIcon ms-2 float-end pe-3" @click="refreshFlows()" v-title="$t('general.updateFlows')">
            <font-awesome-icon icon="sync-alt" class="fa-small" :class="{'fa-spin' : flowRefreshInProgress}" />
          </span>
          <hr />
          <div class="flow-select mt-4">
            <div class="mb-2" style="display:flow-root;">
                <span class="flow-search">
                  <input type="search" v-model="search" class="form-control form-control-sm" :placeholder="$t('general.searchPlaceholder')"/>
                </span>
            </div>
              <ul id="flowContainer" class="mt-1">
                <li v-for="flow in filteredFlows"
                :key="flow.id"
                :class="{'selected': flowId == flow.id}"
                @click="onFlowSelected(flow.id)">
                  {{flow.name}}
                </li>
                <div v-if="flows && flows.length <= 0">{{ $t("notifications.noFlowsFound") }}</div>
              </ul>
          </div>
        </perfect-scrollbar>
      </aside>

      <main ref="FlowDisplay" class="pt-3 col-md-10 col-sm-12">
        <router-view :key="$route.fullPath"></router-view>
      </main>

    </div>
    <div class="row text-purple" v-else>
      <div class="d-flex align-items-center justify-content-center pt-5">
        <span class="d-none d-sm-inline text-center">
          <font-awesome-icon  icon="magic" class="fa-5x" />
          <div class="bigText mt-3">{{ $t("general.selectEnvironmentAndCustomer") }}</div>
        </span>
        <span class="d-block d-sm-none text-center">
          <font-awesome-icon  icon="magic" class="fa-3x" />
          <div class="mt-3">{{ $t("general.selectEnvironmentAndCustomer") }}</div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { AUTH_REQUEST } from '../store/actions/auth';
import {
  GET_FLOWS_REQUEST,
} from '../store/actions/flow';
import { GET_DASHBOARD_REQUEST } from '../store/actions/dashboard';
import { Constants } from '../shared/constants';

export default {
  name: 'MainLayout',
  created() {
    if (this.$route.params.custId && this.$route.params.envId) {
      this.$store.dispatch(GET_FLOWS_REQUEST,
        {
          customerId: this.$route.params.custId,
          environmentId: this.$route.params.envId,
        });
    }
  },
  data() {
    return {
      search: '',
      flowRefreshInProgress: false,
      Constants,
      onlyFailedRuns: false,
      selectedFlowId: '',
    };
  },
  computed: {
    flows() {
      const array = this.$store.state.flow.flows;
      if (array == null) return array;

      // eslint-disable-next-line no-nested-ternary
      const sorted = array.slice().sort((a, b) => ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 0)));
      return sorted;
    },
    filteredFlows() {
      return this.flows ? this.flows.filter((flow) => flow.name.toLowerCase().includes(this.search.toLowerCase())) : this.flows;
    },

    dashboardData() {
      return this.$store.state.dashboard.data;
    },
    customerName() {
      const array = this.$store.state.customer.customers;
      if (array == null) return array;

      return array.filter((customer) => customer.id === this.$route.params.custId)[0].tenantId;
    },
    environmentName() {
      const array = this.$store.state.customer.environments;
      if (array == null) return array;

      return array.filter((env) => env.id === this.$route.params.envId)[0].displayName;
    },
    flowId: {
      get() {
        return this.selectedFlowId;
      },
      set(updatedValue) {
        return updatedValue;
      },
    },
    flowRunGaugeOptions() {
      let arcDelimiter = this.dashboardData.successfulRunPercentage;
      if (arcDelimiter <= 0) arcDelimiter = 0.001;
      if (arcDelimiter >= 100) arcDelimiter = 99.999;

      return {
        needleValue: this.dashboardData.successfulRunPercentage,
        arcDelimiters: [arcDelimiter],
        centralLabel: `${this.dashboardData.successfulRunPercentage} %`,
        arcColors: ['rgba(0, 128, 0, 0.5)', 'rgba(139, 0, 0, 0.5)'],
        rangeLabel: ['0 %', '100 %'],
        chartWidth: '200',
        needleColor: '#747474',
      };
    },
  },
  methods: {
    rowClassName({ row }) {
      return row.status === Constants.FLOW_RUN_SUCCEEDED ? 'success' : 'failed';
    },
    onFlowSelected(flowId) {
      this.selectedFlowId = flowId;
      this.$router.push({ name: 'ListFlowRuns', params: { custId: this.$route.params.custId, envId: this.$route.params.envId, flowId } }).catch(() => {});
      window.setTimeout(() => { this.scrollToElement(); }, 400);
    },
    scrollToElement() {
      const el = this.$refs.FlowDisplay;

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    onHomeSelected() {
      this.selectedFlowId = null;
      this.$router.push({ name: 'Dashboard', params: { custId: this.$route.params.custId, envId: this.$route.params.envId } }).catch(() => {});
      window.setTimeout(() => { this.scrollToElement(); }, 400);
    },
    getFlowName(flowId) {
      const flow = this.flows.filter((f) => f.id === flowId)[0];
      return flow ? flow.name : 'Unbekannt';
    },
    getFlowCount() {
      return this.$store.state.flow.flows ? this.$store.state.flow.flows.length : 0;
    },
    getFlowRunLink(flowId, flowRunId) {
      const { environments } = this.$store.state.customer;
      if (!environments) return environments;

      const environment = environments.filter((env) => env.id === this.$route.params.envId)[0];
      // eslint-disable-next-line max-len
      return `https://flow.microsoft.com/manage/environments/${environment.name}/flows/${flowId}/runs/${flowRunId}`;
    },
    copySuccessHandler() {
      this.$toasted.success('Link kopiert!', { duration: 1200 });
    },
    refreshDashboard() {
      if (!this.refreshInProgress) {
        this.refreshInProgress = true;

        this.$store.dispatch(GET_DASHBOARD_REQUEST,
          {
            customerId: this.$route.params.custId,
            environmentId: this.$route.params.envId,
            onlyFailedRuns: this.onlyFailedRuns,
          })
          .then(() => {
            this.$toasted.success(this.$t('notifications.updated'), { duration: 1200 });
          })
          .catch(() => {
            this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
          })
          .finally(() => {
            this.refreshInProgress = false;
          });
      }
    },
    refreshFlows() {
      if (!this.flowRefreshInProgress) {
        this.flowRefreshInProgress = true;

        this.$store.dispatch(GET_FLOWS_REQUEST,
          {
            customerId: this.$route.params.custId,
            environmentId: this.$route.params.envId,
          })
          .then(() => {
            this.$toasted.success(this.$t('notifications.updated'), { duration: 1200 });
          })
          .catch(() => {
            this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
          })
          .finally(() => {
            this.flowRefreshInProgress = false;
          });
      }
    },
    runSwitchClicked() {
      this.onlyFailedRuns = !this.onlyFailedRuns;
      this.refreshDashboard();
    },
    aquireApiToken() {
      return new Promise((resolve, reject) => {
        if (!this.$store.getters.hasApiKey) {
          this.apiKeyRequest = setInterval(() => {
            if (this.$msal.data.idToken !== '') {
              this.$store
                .dispatch(AUTH_REQUEST, this.$msal.data.idToken)
                .then(() => {
                  console.info('api token aquired');
                  resolve();
                })
                .catch((err) => {
                  console.info('api token not aquired');
                  console.error(err);
                  reject();
                })
                .finally(() => {
                  clearInterval(this.apiKeyRequest);
                });
            }
          }, 500);
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.el-table{
   .el-table__cell {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .el-table__row {
      &.success {
          background-color: rgba($color: #46a218, $alpha: 0.1);
      }

      &.running {
          background-color:rgba($color: #116676, $alpha: 0.1);
      }

      &.failed {
          background-color:rgba($color: #761133, $alpha: 0.1);
      }
  }
}
</style>

<style lang="scss" scoped>
h1 {
  margin: 50px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0;
}

aside {
  background-color: #a21846;
  z-index: 999;
}

main{
  //max-height: calc(100vh - 105px);
  overflow-y: auto;
}

.ps{
  height: calc(100vh - 124px);

  #flowContainer{

    li {
      cursor: pointer;
      overflow-wrap: break-word;
      padding: 6px 6px;
      border-bottom: 1px solid #FFF;
      width: 100%;

      &.selected{
          background-color: #ce1f5a;
        }
      &:hover{
        background: #5f0e29;
        color:whitesmoke;
      }
    }
  }
}

.dashboard-home{
  cursor: pointer;
}

.copyLink{
  cursor:pointer;
}

.resultIcon{
    &.success{
        color:darkgreen;
    }

    &.failed{
        color:darkred;
    }
}

.bigText {
  font-size: 3em;
}

.smallerText{
  font-size: 0.8em;
}

#lastRunContainer{
  tr{
      .cursor-pointer{
        cursor:pointer;
      }

      &.success {
          background-color: rgba($color: #46a218, $alpha: 0.1);
      }

      &.failed {
          background-color:rgba($color: #761133, $alpha: 0.2);
      }
  }
}
</style>
