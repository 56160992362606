/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
export const Subscriptions = Object.freeze([
    {
        name: 'up to 299 users',
        id: 1,
        price: 49,
    },
    {
        name: '300 - 999 users',
        id: 2,
        price: 99,
    },
    {
        name: '1000 - 3999 users',
        id: 3,
        price: 199,
    },
]);

export default Subscriptions;
