import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from '../components/Dashboard.vue';
import MainLayout from '../components/MainLayout.vue';
import AddCustomer from '../components/AddCustomer.vue';
import ListFlowRuns from '../components/ListFlowruns.vue';
import AlertOverview from '../components/AlertOverview.vue';
import CustomerManagement from '../components/CustomerManagement.vue';
import AuthCode from '../components/AuthCode.vue';
import store from '../store';

Vue.use(Router);

const checkForApiKey = (to, from, next) => {
  if (store.getters.hasApiKey) {
    next();
  }
};

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: MainLayout,
      children: [
        {
          path: 'customers/:custId/environments/:envId',
          name: 'Dashboard',
          component: Dashboard,
          beforeEnter: checkForApiKey,
        },
        {
          path: 'customers/:custId/environments/:envId/flows/:flowId',
          name: 'ListFlowRuns',
          component: ListFlowRuns,
          beforeEnter: checkForApiKey,
        },
      ],
    },
    {
      path: '/customers',
      name: 'CustomerManagement',
      component: CustomerManagement,
      beforeEnter: checkForApiKey,
    },
    {
      path: '/customers/:custId/environments/:envId/alerts',
      name: 'Alerts',
      component: AlertOverview,
      beforeEnter: checkForApiKey,
    },
    {
      path: '/customers/add',
      name: 'AddCustomer',
      component: AddCustomer,
      beforeEnter: checkForApiKey,
    },
    {
      path: '/authcode',
      name: 'authcode',
      component: AuthCode,
      beforeEnter: checkForApiKey,
    }],
});
