/* eslint-disable no-shadow */
import axios from 'axios';
import authService from '../../services/authService';
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from '../actions/auth';

const apiKeyIdentifier = 'api-key';

const state = {
  apiKey: localStorage.getItem(apiKeyIdentifier) || '',
  status: '',
};

const getters = {
  hasApiKey: (state) => {
    axios.defaults.headers.common.ApiKey = state.apiKey;
    return !!state.apiKey;
  },
  authStatus: (state) => state.status,
};

const actions = {
  [AUTH_REQUEST]: ({ commit }, idToken) => new Promise((resolve, reject) => {
    commit(AUTH_REQUEST);

    authService.authenticate(idToken)
      .then((resp) => {
        localStorage.setItem(apiKeyIdentifier, resp.data.apiKey);

        console.log('api header set');
        axios.defaults.headers.common.ApiKey = resp.data.apiKey;

        commit(AUTH_SUCCESS, resp);
        resolve();
      })
      .catch((err) => {
        commit(AUTH_ERROR, err);
        localStorage.removeItem(apiKeyIdentifier);
        reject(err);
      });
  }),
  [AUTH_LOGOUT]: ({ commit }) => new Promise((resolve) => {
    commit(AUTH_LOGOUT);
    localStorage.removeItem(apiKeyIdentifier);
    resolve();
  }),
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success';
    state.apiKey = resp.data.apiKey;
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error';
  },
  [AUTH_LOGOUT]: (state) => {
    state.apiKey = '';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
