export const NEW_CUSTOMER_REQUEST = 'NEW_CUSTOMER_REQUEST';
export const NEW_CUSTOMER_ADD_SUCCESS = 'NEW_CUSTOMER_ADD_SUCCESS';
export const NEW_CUSTOMER_ADD_ERROR = 'NEW_CUSTOMER_ADD_ERROR';
export const NEW_CUSTOMER_AUTH_SUCCESS = 'NEW_CUSTOMER_AUTH_SUCCESS';
export const NEW_CUSTOMER_AUTH_ERROR = 'NEW_CUSTOMER_AUTH_ERROR';

export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR';

export const GET_CUSTOMERSWITHTENANTCONNECTION_REQUEST = 'GET_CUSTOMERSWITHTENANTCONNECTION_REQUEST';
export const GET_CUSTOMERSWITHTENANTCONNECTION_SUCCESS = 'GET_CUSTOMERSWITHTENANTCONNECTION_SUCCESS';
export const GET_CUSTOMERSWITHTENANTCONNECTION_ERROR = 'GET_CUSTOMERSWITHTENANTCONNECTION_ERROR';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_REQUEST';

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR';

export const GET_ENVIRONMENTS_REQUEST = 'GET_ENVIRONMENTS_REQUEST';
export const GET_ENVIRONMENTS_SUCCESS = 'GET_ENVIRONMENTS_SUCCESS';
export const GET_ENVIRONMENTS_ERROR = 'GET_ENVIRONMENTS_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const AQUIRE_TOKEN_REQUEST = 'AQUIRE_TOKEN_REQUEST';
export const AQUIRE_TOKEN_SUCCESS = 'AQUIRE_TOKEN_SUCCESS';
export const AQUIRE_TOKEN_ERROR = 'AQUIRE_TOKEN_ERROR';
