<template>
  <modal name="trial-hint" transition="pop-out" :width="modalWidth" :focus-trap="true" :height="400" @closed="closed">
    <div class="box">
      <div class="partition" id="partition-register">
        <div class="partition-title">
          Hinweis
        </div>
        <div class="partition-form pos-relative">
          <div class="hint-text">
            {{ $t("customer.trialEnded") }}
            <br><br>
            <span v-if="Constants.INSTANCE === 'IOZ'">
              {{ $t("customer.trialEndedHintIOZ") }}
            </span>
            <span v-else>
              {{ $t("customer.trialEndedHintGeneric") }}
            </span>
          </div>
          <div class="text-end mt-2 align-bottom">
            <div class="btn-fake" @click="cancel()" id="save-btn">
              {{ $t("general.understood") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { Constants } from '../shared/constants';

const MODAL_WIDTH = 656;

export default {
  name: 'TrialHintModal',
  props: {
    onClose: Function,
  },
  data() {
    return {
      modalWidth: MODAL_WIDTH,
      Constants,
    };
  },
  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
  methods: {
    cancel() {
      this.$modal.hide('trial-hint');
    },
    closed() {
      if (this.onClose) { this.onClose(); }
    },
  },
};
</script>
<style lang="scss">
$background_color: #404142;

.box {
  background: white;
  overflow: hidden;
  height: 400px;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 0 40px black;
  color: #8b8c8d;
  font-size: 0;

  .partition {
    width: 100%;
    height: 100%;

    .partition-title {
      box-sizing: border-box;
      padding: 30px;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 20px;
      font-weight: 300;
    }

    .partition-form {
      padding: 0 20px;
      box-sizing: border-box;
    }

    .pos-relative {
      position: relative;
      height: 300px;
    }
  }

  label,
  small {
    display: block;
    box-sizing: border-box;
    margin-bottom: 2px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    border: 0;
    padding: 2px 8px;
    font-family: inherit;
    transition: 0.5s all;
  }

  .hint-text {
    display: block;
    box-sizing: border-box;
    margin-bottom: 2px;
    width: 100%;
    font-size: 16px;
    line-height: 2;
    border: 0;
    padding: 2px 8px;
    font-family: inherit;
  }

  small {
    font-size: 10px;
    margin: 0;
    padding: 0;
    line-height: 1;
  }

  input[type='text'],
  input[type='email'],
  select {
    display: block;
    box-sizing: border-box;
    margin-bottom: 16px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    border: 0;
    border-bottom: 1px solid #dddedf;
    padding: 4px 8px;
    font-family: inherit;
    transition: 0.5s all;
  }

  .btn-fake {
    background: white;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    min-width: 140px;
    width: 140px;
    text-align: center;
    margin-top: 8px;
    display: inline-block;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: 0.1s all;
    font-size: 10px;

    &:hover {
      border-color: mix(#dddedf, black, 90%);
      color: mix(#8b8c8d, black, 80%);
    }
  }

  .align-bottom {
    position: absolute;
    right: 20px;
    bottom: 5px;
  }

  .large-btn {
    width: 100%;
    background: white;

    span {
      font-weight: 600;
    }

    &:hover {
      color: white !important;
    }
  }

  #cancel-btn,
  #save-btn {
    margin-left: 8px;
  }
}

.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.5s;
}

.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}
</style>
