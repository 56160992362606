<template>
  <div id="app">
    <header v-show="isAuthenticated">
      <nav class="navbar navbar-expand-lg navbar-dark p-3 shadow">
        <router-link :to="{ name: 'Dashboard', params: {custId: this.selectedCustomerId, envId: this.selectedEnvironmentId}}" class="nav-link" exact>
          <img
            src="./assets/logo.png"
            height="60px"
            class="d-inline-block align-top"
            alt="IOZ Logo"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <router-link :to="{ name: 'Dashboard', params: {custId: this.selectedCustomerId, envId: this.selectedEnvironmentId}}" class="nav-link" exact>{{ $t("navigation.home") }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'CustomerManagement' }" class="nav-link" exact>{{ $t("navigation.customerManagement") }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'Alerts', params: {custId: this.selectedCustomerId, envId: this.selectedEnvironmentId} }" class="nav-link" exact>{{ $t("navigation.alerts") }}</router-link>
            </li>
          </ul>
          <span class="navbar-text me-4" v-if="customers">
            <div class="d-block d-sm-inline pe-2 selectLabel">
              {{ $t("general.customer") }}
            </div>
            <select
              name="CustomerSelect"
              class="form-select d-inline my-2 mt-my-0"
              @change="onCustomerChange($event)"
              v-model="customerId"
            >
              <option
                v-for="customer in customers"
                :key="customer.id"
                :value="customer.id"
              >
                {{ customer.tenantId }}
              </option>
            </select>
          </span>
          <span class="navbar-text me-4" v-if="customers">
            <div class="d-block d-sm-inline pe-2 selectLabel">
              {{ $t("general.environment") }}
            </div>
            <select
              name="EnvironmentSelect"
              class="form-select d-inline my-2 my-md-0"
              @change="onEnviromentChange($event)"
              v-model="environmentId"
              :disabled="!customerId">
            <option
                v-for="environment in environments"
                :key="environment.id"
                :value="environment.id">
                {{ environment.displayName }}
              </option>
          </select>
          </span>
          <span class="navbar-text">
            <language-switcher />
            <div id="welcome" class="pt-3 pt-md-0 d-md-inline">{{ $t("general.welcome") }} {{ user !== null ? user.name : ''}}</div>
          </span>
        </div>
      </nav>
    </header>
    <div class="container-fluid">
      <router-view v-if="customers" />
      <div class="row" v-else>
        <div class="d-flex align-items-center justify-content-center mt-5">
          <span class="d-none d-sm-inline text-center">
            <font-awesome-icon  icon="sync-alt" class="fa-spin fa-5x" />
          </span>
          <span class="d-block d-sm-none text-center">
            <font-awesome-icon  icon="sync-alt" class="fa-spin fa-3x" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AUTH_REQUEST } from './store/actions/auth';
import LanguageSwitcher from './components/LanguageSwitcher.vue';
import { GET_CUSTOMERS_REQUEST, GET_ENVIRONMENTS_REQUEST } from './store/actions/customer';
import { GET_FLOWS_REQUEST, CLEAR_FLOWS_REQUEST, CLEAR_FLOW_RUNS_REQUEST } from './store/actions/flow';
import { CLEAR_DASHBOARD_REQUEST, GET_DASHBOARD_REQUEST } from './store/actions/dashboard';

export default {
  name: 'App',
  components: {
    LanguageSwitcher,
  },
  created() {
    if (!this.$msal.isAuthenticated()) {
      this.$msal.signIn();
    }

    this.aquireApiToken().then(() => {
      this.$store.dispatch(GET_CUSTOMERS_REQUEST).then(() => {
        this.selectedCustomerId = this.$route.params.custId;
      });
      this.$store.dispatch(GET_ENVIRONMENTS_REQUEST, this.$route.params.custId).then(() => {
        this.selectedEnvironmentId = this.$route.params.envId;
      });
      this.isAuthenticated = true;
    })
      .catch(() => {
        this.$toasted.error(this.$t('notifications.unauthorized'), { duration: 1000 });
        window.setTimeout(() => { this.$msal.signOut(); }, 1000);
      });
  },
  data() {
    return {
      apiKeyRequest: null,
      selectedCustomerId: null,
      selectedEnvironmentId: null,
      isAuthenticated: false,
    };
  },
  computed: {
    user() {
      let user = null;
      if (this.$msal.isAuthenticated()) {
        user = {
          ...this.$msal.data.user,
        };
        if (process.env.NODE_ENV !== 'production') { console.log(this.$msal); }
      }
      return user;
    },
    customers() {
      const array = this.$store.state.customer.customers;
      if (array == null) return array;

      const filtered = array.filter((customer) => customer.inactive === false);
      // eslint-disable-next-line no-nested-ternary
      const sorted = filtered.slice().sort((a, b) => ((a.tenantId.toLowerCase() > b.tenantId.toLowerCase()) ? 1 : ((a.tenantId.toLowerCase() < b.tenantId.toLowerCase()) ? -1 : 0)));
      return sorted;
    },
    environments() {
      const array = this.$store.state.customer.environments;
      if (array == null) return array;
      // eslint-disable-next-line no-nested-ternary
      const sorted = array.slice().sort((a, b) => ((a.displayName.toLowerCase() > b.displayName.toLowerCase()) ? 1 : ((a.displayName.toLowerCase() < b.displayName.toLowerCase()) ? -1 : 0)));
      return sorted;
    },
    customerId: {
      get() {
        return this.selectedCustomerId;
      },
      set(updatedValue) {
        return updatedValue;
      },
    },
    environmentId: {
      get() {
        return this.selectedEnvironmentId;
      },
      set(updatedValue) {
        return updatedValue;
      },
    },
  },
  methods: {
    onCustomerChange(event) {
      if (event.target.value !== '') {
        this.selectedCustomerId = event.target.value;
        this.$store.dispatch(CLEAR_FLOWS_REQUEST);
        this.$store.dispatch(CLEAR_DASHBOARD_REQUEST);
        this.$store.dispatch(GET_ENVIRONMENTS_REQUEST, event.target.value);
      }
    },
    onEnviromentChange(event) {
      if (event.target.value !== '') {
        this.selectedEnvironmentId = event.target.value;
        this.$store.dispatch(CLEAR_FLOW_RUNS_REQUEST);
        this.$store.dispatch(GET_FLOWS_REQUEST, { customerId: this.$route.params.custId, environmentId: event.target.value });
        this.$store.dispatch(GET_DASHBOARD_REQUEST, { customerId: this.$route.params.custId, environmentId: event.target.value })
          .then(() => {
            this.$router.push({ name: 'Dashboard', params: { custId: this.customerId, envId: event.target.value } });
          });
      }
    },
    aquireApiToken() {
      return new Promise((resolve, reject) => {
        if (!this.$store.getters.hasApiKey) {
          this.apiKeyRequest = setInterval(() => {
            if (this.$msal.data.idToken !== '') {
              this.$store
                .dispatch(AUTH_REQUEST, this.$msal.data.idToken)
                .then(() => {
                  console.info('api token aquired');
                  resolve();
                })
                .catch((err) => {
                  console.info('api token not aquired');
                  console.error(err);
                  reject();
                })
                .finally(() => {
                  clearInterval(this.apiKeyRequest);
                });
            }
          }, 500);
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style lang="scss">
html,
body {
  font-family: 'Saira Semi Condensed', sans-serif !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  a{
    text-decoration: none !important;
 }
}

nav {
  background-color:#a21846;

  &.navbar {
    .nav-item > .nav-link.router-link-exact-active,
    .nav-item > .nav-link.router-link-active {
      color: white !important;
    }

    .navbar-text {
      color:white;

      select {
        width:250px;
      }
    }
  }
}

.fa-small{
    font-size: 0.8em;
}

.refreshIcon,
.clickable{
  cursor:pointer;
}

.bg-purple {
  background-color: #591195 !important;
  color: white !important;
}

.link-hover {
  padding: 0 4px;
  cursor: pointer;

  &:hover{
    background-color: rgba($color: #FFF, $alpha: 0.5);
  }
}

.text-purple {
  color: #591195 !important;
}
</style>
