/* eslint-disable no-shadow */
import {
  NEW_CUSTOMER_REQUEST,
  NEW_CUSTOMER_ADD_SUCCESS,
  NEW_CUSTOMER_ADD_ERROR,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  GET_CUSTOMERSWITHTENANTCONNECTION_REQUEST,
  GET_CUSTOMERSWITHTENANTCONNECTION_SUCCESS,
  GET_CUSTOMERSWITHTENANTCONNECTION_ERROR,
  GET_ENVIRONMENTS_REQUEST,
  GET_ENVIRONMENTS_SUCCESS,
  GET_ENVIRONMENTS_ERROR,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_ERROR,
  AQUIRE_TOKEN_REQUEST,
  AQUIRE_TOKEN_SUCCESS,
  AQUIRE_TOKEN_ERROR,
} from '../actions/customer';
import apiService from '../../services/customerService';

const state = {
  status: '',
  customers: null,
  environments: null,
};

const actions = {
  [NEW_CUSTOMER_REQUEST]: ({ commit }, customerData) => new Promise((resolve, reject) => {
    commit(NEW_CUSTOMER_REQUEST);

    apiService.addCustomer(customerData)
      .then((response) => {
        commit(NEW_CUSTOMER_ADD_SUCCESS);
        resolve(response);
      })
      .catch((err) => {
        commit(NEW_CUSTOMER_ADD_ERROR);
        reject(err);
      });
  }),

  [GET_CUSTOMERS_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_CUSTOMERS_REQUEST);

    apiService.getCustomers()
      .then((response) => {
        commit(GET_CUSTOMERS_SUCCESS, response);
        resolve(response);
      })
      .catch((err) => {
        commit(GET_CUSTOMERS_ERROR);
        reject(err);
      });
  }),

  [GET_CUSTOMERSWITHTENANTCONNECTION_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_CUSTOMERSWITHTENANTCONNECTION_REQUEST);

    apiService.getCustomersWithTenantConnection()
      .then((response) => {
        commit(GET_CUSTOMERSWITHTENANTCONNECTION_SUCCESS);
        resolve(response);
      })
      .catch((err) => {
        commit(GET_CUSTOMERSWITHTENANTCONNECTION_ERROR);
        reject(err);
      });
  }),

  [GET_ENVIRONMENTS_REQUEST]: ({ commit }, customerId) => new Promise((resolve, reject) => {
    if (customerId) {
      commit(GET_ENVIRONMENTS_REQUEST);
      apiService.getEnvironments(customerId)
        .then((response) => {
          commit(GET_ENVIRONMENTS_SUCCESS, response);
          resolve(response.data);
        })
        .catch((err) => {
          commit(GET_ENVIRONMENTS_ERROR);
          reject(err);
        });
    }
  }),

  [UPDATE_CUSTOMER_REQUEST]: ({ commit }, customer) => new Promise((resolve, reject) => {
    commit(UPDATE_CUSTOMER_REQUEST);

    apiService.updateCustomer(customer)
      .then((response) => {
        commit(UPDATE_CUSTOMER_SUCCESS);
        resolve(response);
      })
      .catch((err) => {
        commit(UPDATE_CUSTOMER_ERROR);
        reject(err);
      });
  }),

  [RESET_PASSWORD_REQUEST]: ({ commit }, customerId) => new Promise((resolve, reject) => {
    commit(RESET_PASSWORD_REQUEST);

    apiService.resetCustomerPassword(customerId)
      .then((response) => {
        commit(RESET_PASSWORD_SUCCESS);
        resolve(response);
      })
      .catch((err) => {
        commit(RESET_PASSWORD_ERROR);
        reject(err);
      });
  }),

  [DELETE_CUSTOMER_REQUEST]: ({ commit }, customerId) => new Promise((resolve, reject) => {
    commit(DELETE_CUSTOMER_REQUEST);

    apiService.deleteCustomer(customerId)
      .then((response) => {
        commit(DELETE_CUSTOMER_SUCCESS);
        resolve(response);
      })
      .catch((err) => {
        commit(DELETE_CUSTOMER_ERROR);
        reject(err);
      });
  }),

  [AQUIRE_TOKEN_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(AQUIRE_TOKEN_REQUEST);

    apiService.aquireToken(data.customerId, data.authCode)
      .then((response) => {
        commit(AQUIRE_TOKEN_SUCCESS);
        resolve(response);
      })
      .catch((err) => {
        commit(AQUIRE_TOKEN_ERROR);
        reject(err);
      });
  }),
};

const mutations = {
  [NEW_CUSTOMER_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [NEW_CUSTOMER_ADD_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [NEW_CUSTOMER_ADD_ERROR]: (state) => {
    state.status = 'error';
  },

  [GET_CUSTOMERS_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_CUSTOMERS_SUCCESS]: (state, response) => {
    state.status = 'success';
    state.customers = response.data;
  },
  [GET_CUSTOMERS_ERROR]: (state) => {
    state.status = 'error';
  },

  [GET_CUSTOMERSWITHTENANTCONNECTION_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_CUSTOMERSWITHTENANTCONNECTION_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [GET_CUSTOMERSWITHTENANTCONNECTION_ERROR]: (state) => {
    state.status = 'error';
  },

  [GET_ENVIRONMENTS_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_ENVIRONMENTS_SUCCESS]: (state, response) => {
    state.status = 'success';
    state.environments = response.data;
  },
  [GET_ENVIRONMENTS_ERROR]: (state) => {
    state.status = 'error';
  },

  [UPDATE_CUSTOMER_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [UPDATE_CUSTOMER_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [UPDATE_CUSTOMER_ERROR]: (state) => {
    state.status = 'error';
  },

  [RESET_PASSWORD_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [RESET_PASSWORD_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [RESET_PASSWORD_ERROR]: (state) => {
    state.status = 'error';
  },

  [DELETE_CUSTOMER_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [DELETE_CUSTOMER_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [DELETE_CUSTOMER_ERROR]: (state) => {
    state.status = 'error';
  },

  [AQUIRE_TOKEN_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [AQUIRE_TOKEN_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [AQUIRE_TOKEN_ERROR]: (state) => {
    state.status = 'error';
  },
};

export default {
  state,
  actions,
  mutations,
};
