<template>
  <div class="col mt-4 mx-2">
    <h4 class="mb-2 mt-2 font-weight-bold">
      {{ $t("customer.addCustomer") }}
    </h4>
    <form class="add-customer-form col-12 col-md-4" @submit.prevent="addCustomer">
      <div class="form-group" v-if="Constants.INSTANCE === 'IOZ'">
        <label class="mt-2">Bexio Kunden-ID</label>
        <input :required="!isTrialUser" v-model.number="bexioCustomerId" type="text" pattern="\d*" class="form-control"
          placeholder="Kundennummer Bexio" title="Nur Zahlen erlaubt" :disabled="addCustomerSuccess" />
      </div>
      <div class="form-group">
        <label class="mt-2">Tenant</label>
        <input required v-model="tenantId" pattern="[A-Za-z0-9]*" type="text" class="form-control"
          placeholder="Tenant (<tenant>.onmicrosoft.com)" :title="$t('customer.customerTenantNameHint')"
          :disabled="addCustomerSuccess" />
        <div class="error" v-if="customerAlreadyExists">{{ $t("customer.alreadyExists") }}</div>
      </div>
      <div class="form-group" v-if="Constants.INSTANCE === 'IOZ'">
        <label class="mt-2">{{ $t("customer.monthlyPrice") }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              CHF
            </span>
          </div>
          <input required v-model.number="billingprice" type="number" step=".01" class="form-control"
            placeholder="Standard: 49.-" aria-describedby="basic-addon1" :disabled="addCustomerSuccess || isTrialUser" />
          <div class="input-group-append">
            <div class="input-group-text">
              <input class="form-check-input trialCheckbox" type="checkbox" value="" id="TrialUser"
                v-model="isTrialUser" />
              <label class="form-check-label ms-2 trialLabel" for="TrialUser">
                Trial
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group" v-else>
        <label class="mt-2">{{ $t("customer.subscription") }}</label>
        <div class="input-group">
          <select :required="!isTrialUser" :disabled="isTrialUser" name="SubscriptionSelect" class="form-select d-inline"
            v-model="subscriptionId">
            <option v-for="sub in Subscriptions" :key="sub.id" :value="sub.id">
              {{ sub.name }}
            </option>
          </select>
          <div class="input-group-append">
            <div class="input-group-text">
              <input class="form-check-input trialCheckbox" type="checkbox" value="" id="TrialUser"
                v-model="isTrialUser" />
              <label class="form-check-label ms-2 trialLabel" for="TrialUser">
                Trial
              </label>
            </div>
          </div>
        </div>
        <small>For more than 4000 Users please contact IOZ AG</small>
      </div>
      <div class="form-group">
        <label class="mt-2">{{ $t("customer.billingReference") }}</label>
        <input v-model="billingreference" type="text" class="form-control" placeholder="optional"
          :disabled="addCustomerSuccess" />
      </div>
      <div>
        <button type="submit" class="btn btn-primary mt-2" :disabled="!tenantId ||
          inProgress ||
          addCustomerSuccess
          ">
          <span v-show="!addCustomerSuccess && !inProgress">{{ $t("general.continue") }}</span>
          <font-awesome-icon icon="sync" class="fa-spin" v-show="inProgress" />
          <font-awesome-icon icon="check" v-show="addCustomerSuccess" />
        </button>
        <div class="btn btn-secondary mt-2 mx-2" @click="goBack">
          {{ $t("general.cancel") }}
        </div>
      </div>
    </form>
    <div class="customer-info mt-4" v-show="customerData.username">
      <div class="mb-3">
        {{ $t("customer.customerCreatedHint1") }}
        <a :href="Constants.CUSTOMERFRONTENDURL" target="_blank">{{ $t("customer.customerCreatedHint2") }}</a>:
      </div>
      <div>
        <span class="mr-1 font-weight-bold">{{ $t("customer.customerNumber") }}:</span>&nbsp;<span>{{
          customerData.username
        }}</span>
      </div>
      <div>
        <span class="mr-1 font-weight-bold">{{ $t("general.password") }}:</span>&nbsp;<span>{{
          customerData.password
        }}</span>
      </div>
      <div class="text-danger font-weight-bold mt-3">
        {{ $t("customer.customerCreatedHint3") }}
      </div>

      <div class="mt-4">
        <div class="mb-2">{{ $t("customer.customerCreatedHint4") }}:</div>
        <a class="btn btn-success" target="_blank" :href="tenantConnectionLink" :disabled="!tenantId ||
          inProgress">
          {{ $t("customer.connectTenant") }}
        </a>
        <span class="copyLink ms-3" :title="$t('customer.copyTenantReconnectLink')"
          v-clipboard="() => { return tenantConnectionLink }" v-clipboard:success="copySuccessHandler">
          <font-awesome-icon icon="clipboard" />
        </span>
      </div>
    </div>

  </div>
</template>

<script>
import {
  NEW_CUSTOMER_REQUEST,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERSWITHTENANTCONNECTION_REQUEST,
} from '../store/actions/customer';
import { Constants } from '../shared/constants';
import { Subscriptions } from '../shared/subscriptions';

export default {
  name: 'AddCustomer',
  data() {
    return {
      inProgress: false,
      addCustomerSuccess: false,
      bexioCustomerId: null,
      tenantId: '',
      billingprice: 49,
      subscriptionId: 1,
      billingreference: '',
      isTrialUser: false,
      customerData: {},
      customers: null,
      customerAlreadyExists: false,
      Constants,
      Subscriptions,
    };
  },
  created() {
    this.$store
      .dispatch(GET_CUSTOMERSWITHTENANTCONNECTION_REQUEST)
      .then((resp) => {
        this.customers = resp.data;
      });
  },
  computed: {
    tenantConnectionLink() {
      return `https://login.microsoftonline.com/${this.tenantId}.onmicrosoft.com/oauth2/v2.0/authorize`
        + '?response_type=code'
        + '&scope=https%3A%2F%2Fmanagement.azure.com%2F%2Fuser_impersonation%20user.readbasic.all+openid%20offline_access'
        + '&client_id=baf4cf8d-d6ee-45ed-a957-7eac57734c41'
        + `&redirect_uri=${process.env.VUE_APP_AUTH_ENDPOINT}`
        + `&state=${this.customerData.id}`
        + '&response_mode=query';
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    copySuccessHandler() {
      this.showSuccessToast(this.$t('notifications.copyLinkSuccess'));
    },
    showSuccessToast(message) {
      this.$toasted.success(message, { duration: 1200 });
    },
    addCustomer() {
      let {
        bexioCustomerId,
        billingprice,
      } = this;

      const {
        tenantId,
        billingreference,
        isTrialUser,
      } = this;

      if (Constants.INSTANCE === 'Prodware') {
        bexioCustomerId = 1668;
        billingprice = Subscriptions.find((x) => x.id === this.subscriptionId).price;
      } else if (Constants.INSTANCE === 'Castelli') {
        bexioCustomerId = 1681;
        billingprice = Subscriptions.find((x) => x.id === this.subscriptionId).price;
      } else if (Constants.INSTANCE === 'Dinotronic') {
        bexioCustomerId = 550;
        billingprice = Subscriptions.find((x) => x.id === this.subscriptionId).price;
      }

      this.inProgress = true;
      this.addCustomerSuccess = false;
      this.customerAlreadyExists = false;

      if (this.customers.find((c) => c.tenantId.toLowerCase() === tenantId.toLowerCase())) {
        this.customerAlreadyExists = true;
        this.inProgress = false;
        return;
      }

      this.$store
        .dispatch(NEW_CUSTOMER_REQUEST, {
          bexioCustomerId,
          tenantId,
          billingprice,
          billingreference,
          isTrialUser,
        })
        .then((resp) => {
          this.customerData = resp.data;
          this.addCustomerSuccess = true;
          this.$store.dispatch(GET_CUSTOMERS_REQUEST);
          this.$toasted.success(this.$t('notifications.saved'), { duration: 2400 });
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error(this.$t('notifications.genericError'), {
            duration: 2400,
          });
        })
        .finally(() => {
          this.inProgress = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: red;
}

.trialCheckbox {
  margin-top: 0px;
}

.copyLink {
  cursor: pointer;
}
</style>
