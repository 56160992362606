import axios from 'axios';
import serviceconfig from './serviceConfig';

export default {
  async addCustomer(customerData) {
    return axios.post(`${serviceconfig.apiBase}/customers`, customerData);
  },

  async getCustomers() {
    return axios.get(`${serviceconfig.apiBase}/customers`);
  },

  async getCustomersWithTenantConnection() {
    return axios.get(`${serviceconfig.apiBase}/customers`);
  },

  async getEnvironments(customerId) {
    return axios.get(`${serviceconfig.apiBase}/customers/${customerId}/environments`);
  },

  async updateCustomer(customerData) {
    return axios.put(`${serviceconfig.apiBase}/customers/${customerData.id}`, customerData);
  },

  async resetCustomerPassword(customerId) {
    return axios.post(`${serviceconfig.apiBase}/customers/${customerId}/resetPassword`);
  },

  async deleteCustomer(customerId) {
    return axios.delete(`${serviceconfig.apiBase}/customers/${customerId}`);
  },

  async aquireToken(customerId, authCode) {
    return axios.post(`${serviceconfig.apiBase}/customers/${customerId}/token?authCode=${authCode}`);
  },
};
