/* eslint-disable no-shadow */
import {
  GET_FLOW_ALERTS_REQUEST,
  GET_FLOW_ALERTS_SUCCESS,
  GET_FLOW_ALERTS_ERROR,
  DELETE_FLOW_ALERT_REQUEST,
  DELETE_FLOW_ALERT_SUCCESS,
  DELETE_FLOW_ALERT_ERROR,
  ADD_FLOW_ALERT_REQUEST,
  ADD_FLOW_ALERT_SUCCESS,
  ADD_FLOW_ALERT_ERROR,
  GET_ENVIRONMENT_ALERTS_REQUEST,
  GET_ENVIRONMENT_ALERTS_SUCCESS,
  GET_ENVIRONMENT_ALERTS_ERROR,
  DELETE_ENVIRONMENT_ALERT_REQUEST,
  DELETE_ENVIRONMENT_ALERT_SUCCESS,
  DELETE_ENVIRONMENT_ALERT_ERROR,
  ADD_ENVIRONMENT_ALERT_REQUEST,
  ADD_ENVIRONMENT_ALERT_SUCCESS,
  ADD_ENVIRONMENT_ALERT_ERROR,
} from '../actions/alert';
import constants from '../../shared/constants';
import apiService from '../../services/alertService';

const state = {
  status: '',
  alerts: [],
};

const actions = {
  [GET_FLOW_ALERTS_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(GET_FLOW_ALERTS_REQUEST);

    apiService.getAlerts(data.customerId, data.environmentId, data.flowId, constants.ALERT_SCOPE_FLOW)
      .then((resp) => {
        commit(GET_FLOW_ALERTS_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        commit(GET_FLOW_ALERTS_ERROR);
        reject(err);
      });
  }),

  [DELETE_FLOW_ALERT_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(DELETE_FLOW_ALERT_REQUEST);
    apiService.deleteAlert(data.customerId, data.environmentId, data.flowId, data.alertId, constants.ALERT_SCOPE_FLOW)
      .then(() => {
        commit(DELETE_FLOW_ALERT_SUCCESS);
        resolve();
      })
      .catch((err) => {
        commit(DELETE_FLOW_ALERT_ERROR);
        reject(err);
      });
  }),

  [ADD_FLOW_ALERT_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(ADD_FLOW_ALERT_REQUEST);
    apiService.addAlert(data.customerId, data.environmentId, data.flowId, constants.ALERT_SCOPE_FLOW, data.body)
      .then(() => {
        commit(ADD_FLOW_ALERT_SUCCESS);
        resolve();
      })
      .catch((err) => {
        commit(ADD_FLOW_ALERT_ERROR);
        reject(err);
      });
  }),

  [GET_ENVIRONMENT_ALERTS_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(GET_ENVIRONMENT_ALERTS_REQUEST);

    apiService.getAlerts(data.customerId, data.environmentId, data.flowId, constants.ALERT_SCOPE_ENVIRONMENT)
      .then((resp) => {
        commit(GET_ENVIRONMENT_ALERTS_SUCCESS, resp);
        resolve();
      })
      .catch((err) => {
        commit(GET_ENVIRONMENT_ALERTS_ERROR);
        reject(err);
      });
  }),

  [DELETE_ENVIRONMENT_ALERT_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(DELETE_ENVIRONMENT_ALERT_REQUEST);
    apiService.deleteAlert(data.customerId, data.environmentId, null, data.alertId, constants.ALERT_SCOPE_ENVIRONMENT)
      .then(() => {
        commit(DELETE_ENVIRONMENT_ALERT_SUCCESS);
        resolve();
      })
      .catch((err) => {
        commit(DELETE_ENVIRONMENT_ALERT_ERROR);
        reject(err);
      });
  }),

  [ADD_ENVIRONMENT_ALERT_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(ADD_ENVIRONMENT_ALERT_REQUEST);
    apiService.addAlert(data.customerId, data.environmentId, null, constants.ALERT_SCOPE_FLOW, data.body)
      .then(() => {
        commit(ADD_ENVIRONMENT_ALERT_SUCCESS);
        resolve();
      })
      .catch((err) => {
        commit(ADD_ENVIRONMENT_ALERT_ERROR);
        reject(err);
      });
  }),
};

const mutations = {
  [GET_FLOW_ALERTS_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_FLOW_ALERTS_SUCCESS]: (state, resp) => {
    state.status = 'success';
    state.alerts = resp.data;
  },
  [GET_FLOW_ALERTS_ERROR]: (state) => {
    state.status = 'error';
  },

  [DELETE_FLOW_ALERT_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [DELETE_FLOW_ALERT_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [DELETE_FLOW_ALERT_ERROR]: (state) => {
    state.status = 'error';
  },

  [ADD_FLOW_ALERT_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [ADD_FLOW_ALERT_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [ADD_FLOW_ALERT_ERROR]: (state) => {
    state.status = 'error';
  },

  [GET_ENVIRONMENT_ALERTS_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_ENVIRONMENT_ALERTS_SUCCESS]: (state, resp) => {
    state.status = 'success';
    state.alerts = resp.data;
  },
  [GET_ENVIRONMENT_ALERTS_ERROR]: (state) => {
    state.status = 'error';
  },

  [ADD_ENVIRONMENT_ALERT_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [ADD_ENVIRONMENT_ALERT_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [ADD_ENVIRONMENT_ALERT_ERROR]: (state) => {
    state.status = 'error';
  },

  [DELETE_ENVIRONMENT_ALERT_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [DELETE_ENVIRONMENT_ALERT_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [DELETE_ENVIRONMENT_ALERT_ERROR]: (state) => {
    state.status = 'error';
  },
};

export default {
  state,
  actions,
  mutations,
};
