<template>
    <div style="text-align:center;">
        <div v-if="!success">
            <font-awesome-icon icon="sync" class="fa-spin fa-3x mt-5" />
            <div class="mt-3">{{ $t("general.tenantConfiguration") }}<br>{{ $t("general.inProgress") }}...</div>
        </div>
        <div v-else class="successContainer">
            <font-awesome-icon icon="sync" class="fa-3x mt-5" />
            <div class="mt-3">{{ $t("general.tenantConfiguration") }}<br>{{ $t("general.completed") }}!</div>
        </div>
    </div>
</template>

<script>
import {
  AQUIRE_TOKEN_REQUEST,
} from '../store/actions/customer';

export default {
  name: 'AuthCode',
  data() {
    return {
      success: false,
    };
  },
  mounted() {
    const data = {
      authCode: this.$route.query.code,
      customerId: this.$route.query.state,
    };

    this.$store
      .dispatch(AQUIRE_TOKEN_REQUEST, data)
      .then(() => {
        this.success = true;
        this.$toasted.success(this.$t('notifications.tenantSuccessfullyConnected'), { duration: 2400 });
        setTimeout(this.goNext, 4000);
      })
      .catch((err) => {
        this.success = false;
        console.log(err);
        this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
      });
  },
  methods: {
    goNext() {
      this.$router.push({ name: 'CustomerManagement' });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
