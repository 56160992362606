<template>
  <div class="mt-4">
    <trial-hint-modal />
    <div class="row">
      <div v-show="!customers">
        <font-awesome-icon icon="sync" class="fa-spin fa-3x mt-4 ms-4" />
      </div>
      <div class="col-sm-12 col-md-4" v-if="customers">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-2 mt-2 font-weight-bold">
            {{ $t("navigation.customerManagement") }}
          </h3>
          <router-link :to="{ name: 'AddCustomer' }">
            <div class="btn btn-primary">
              {{ $t("customer.addCustomer") }}
            </div>
          </router-link>
        </div>
        <div id="customerList" class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">{{ $t("customer.customerName") }}</th>
                <th style="width: 10%" scope="col">{{ $t("general.details") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="customer in customers" :key="customer.id"
                :class="{ selected: selectedCustomerId == customer.id, inactive: customer.inactive }"
                @click="onCustomerSelected(customer.id)">
                <td scope="row">{{ customer.tenantId }}</td>
                <td scope="row">
                  <font-awesome-icon icon="arrow-alt-circle-right" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="col-sm-12 col-md-8" v-if="selectedCustomer" id="customerDetails">
        <h4>{{ $t("general.details") }}</h4>
        <form class="update-customer-form" @submit.prevent="updateCustomer">
          <div class="form-group">
            <label class="mt-2">Tenant</label>
            <input required v-model="selectedCustomer.tenantId" type="text" class="form-control"
              placeholder="Tenant-Name (<tenantname>.onmicrosoft.com)" disabled />
          </div>
          <div class="form-group" v-if="Constants.INSTANCE === 'IOZ'">
            <label class="mt-2">Bexio Kunden-ID</label>
            <input :required="!isTrialUser" v-model.number="selectedCustomer.bexioCustomerId" max="2147483647"
              type="number" class="form-control" placeholder="Kundennummer aus Bexio" />
          </div>
          <div class="form-group" v-if="Constants.INSTANCE === 'IOZ'">
            <label class="mt-2">{{ $t("customer.monthlyPrice") }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  CHF
                </span>
              </div>
              <input required v-model.number="selectedCustomer.billingPrice" type="number" step=".01" min="0"
                class="form-control" placeholder="Standard: 49.-" aria-describedby="basic-addon1" />
            </div>
          </div>
          <div class="form-group" v-else>
            <label class="mt-2">{{ $t("customer.subscription") }}</label>
            <div class="input-group">
              <select :required="!isTrialUser" name="SubscriptionSelect" class="form-select d-inline"
                v-model="subscriptionId">
                <option v-for="sub in Subscriptions" :key="sub.id" :value="sub.id">
                  {{ sub.name }}
                </option>
              </select>
            </div>
            <small>{{ $t('customer.moreUsersHint') }}</small>
          </div>
          <div class="form-group">
            <label class="mt-2">{{ $t("customer.billingReference") }}</label>
            <input v-model="selectedCustomer.billingReference" type="text" class="form-control" placeholder="optional"
              :disabled="updateInProgress" />
          </div>
          <div class="form-check mt-2">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
              v-model="selectedCustomer.inactive" :checked="selectedCustomer.inactive ? true : false" />
            <label class="form-check-label" for="flexCheckChecked">
              {{ $t("customer.customerInactive") }}
            </label>
          </div>
          <div class="form-check mt-2">
            <input class="form-check-input" type="checkbox" ref="cbTrialUser" id="TrialUser"
              v-model="selectedCustomer.isTrialUser" :checked="selectedCustomer.isTrialUser ? true : false"
              @change="checkTrialEnd" />
            <label class="form-check-label" for="TrialUser" v-if="!selectedCustomer.isTrialUser">
              Trial
            </label>
            <label class="form-check-label" for="TrialUser" v-else>
              Trial ({{ $t("general.ends") }}: {{ selectedCustomer.trialEndsOn | date }})
            </label>
          </div>
          <div>
            <button type="submit" class="btn btn-primary mt-2" :disabled="updateInProgress">
              <span v-show="!updateInProgress">{{ $t("general.save") }}</span>
              <font-awesome-icon icon="sync" class="fa-spin" v-show="updateInProgress" />
            </button>
          </div>
        </form>
        <hr />
        <div class="btn btn-danger mt-1" :disabled="deleteInProgress" @click="deleteCustomer()">
          <span v-show="!deleteInProgress">
            {{ $t("customer.deleteCustomer") }}
          </span>
          <font-awesome-icon icon="sync" class="fa-spin" v-show="deleteInProgress" />
        </div>
        <hr />
        <h4>
          {{ $t("general.loginDetails") }} <a :href="Constants.CUSTOMERFRONTENDURL" target="_blank">
            {{ $t("general.customerPortal") }}<font-awesome-icon icon="external-link-alt" class="fa-small ms-2" />
          </a>
        </h4>
        <div>{{ $t("customer.customerNumber") }}: {{ selectedCustomer.username }}</div>
        <div v-show="resetPasswordSuccess">{{ $t("general.password") }}: {{ newPassword }}<span class="copyLink ms-1"
            :title="$t('customer.copyPassword')" v-clipboard="() => getNewPassword()"
            v-clipboard:success="copyPasswordSuccessHandler">
            <font-awesome-icon icon="clipboard" />
          </span></div>
        <button class="btn btn-primary my-2" :disabled="resetInProgress || resetPasswordSuccess"
          @click="resetCustomerPassword()">
          <span v-show="!resetPasswordSuccess"> {{ $t("customer.resetPassword") }}</span>
          <font-awesome-icon icon="check" v-show="resetPasswordSuccess" />
        </button>
        <hr />
        <h4>
          {{ $t("general.apiKey") }}
        </h4>
        <div>{{ selectedCustomer.apiKey }}</div>
        <hr />
        <h4>
          {{ $t("customer.customerTenantConnection") }}
        </h4>
        <div class="mt-4">
          <a class="btn btn-primary text-white" target="_blank" :href="tenantConnectionLink">
            {{ $t("customer.resetCustomerTenantConnection") }}
          </a>
          <span class="copyLink ms-3" :title="$t('customer.copyTenantReconnectLink')"
            v-clipboard="() => { return tenantConnectionLink }" v-clipboard:success="copySuccessHandler">
            <font-awesome-icon icon="clipboard" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_CUSTOMERSWITHTENANTCONNECTION_REQUEST,
  UPDATE_CUSTOMER_REQUEST,
  GET_CUSTOMERS_REQUEST,
  RESET_PASSWORD_REQUEST,
  DELETE_CUSTOMER_REQUEST,
  GET_ENVIRONMENTS_REQUEST,
} from '../store/actions/customer';
import { Constants } from '../shared/constants';
import { Subscriptions } from '../shared/subscriptions';

import TrialHintModal from './TrialHint.vue';

export default {
  name: 'CustomerManagement',
  components: {
    TrialHintModal,
  },
  data() {
    return {
      customers: null,
      selectedCustomerId: '',
      updateCustomerSuccess: false,
      resetPasswordSuccess: false,
      deleteCustomerSuccess: false,
      updateInProgress: false,
      resetInProgress: false,
      deleteInProgress: false,
      newPassword: '',
      isTrialUser: false,
      subscriptionId: null,
      Constants,
      Subscriptions,
    };
  },
  computed: {
    selectedCustomer() {
      const customer = this.customers && this.selectedCustomerId
        ? this.customers
          .map((a) => ({ ...a }))
          .filter((c) => c.id === this.selectedCustomerId)[0]
        : null;

      if (customer && Constants.INSTANCE !== 'IOZ') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.subscriptionId = Subscriptions.find((x) => x.price === customer.billingPrice).id;
      }

      return customer;
    },
    tenantConnectionLink() {
      return `https://login.microsoftonline.com/${this.selectedCustomer.tenantId}.onmicrosoft.com/oauth2/v2.0/authorize`
        + '?response_type=code'
        + '&scope=https%3A%2F%2Fmanagement.azure.com%2F%2Fuser_impersonation%20user.readbasic.all+openid%20offline_access'
        + '&client_id=baf4cf8d-d6ee-45ed-a957-7eac57734c41'
        + `&redirect_uri=${process.env.VUE_APP_AUTH_ENDPOINT}`
        + `&state=${this.selectedCustomerId}`
        + '&response_mode=query';
    },
  },
  created() {
    this.$store
      .dispatch(GET_CUSTOMERSWITHTENANTCONNECTION_REQUEST)
      .then((resp) => {
        this.customers = resp.data;
      });
  },
  methods: {
    onCustomerSelected(customerId) {
      if (!this.updateInProgress && !this.resetInProgress) {
        this.selectedCustomerId = customerId;
        this.resetPasswordSuccess = false;
        this.isTrialUser = this.selectedCustomer.isTrialUser;
      }
    },
    updateCustomer() {
      this.updateInProgress = true;
      this.updateCustomerSuccess = false;

      if (this.selectedCustomer.bexioCustomerId === '') this.selectedCustomer.bexioCustomerId = null;

      if (Constants.INSTANCE !== 'IOZ') {
        this.selectedCustomer.billingPrice = Subscriptions.find((x) => x.id === this.subscriptionId).price;
      }

      this.$store
        .dispatch(UPDATE_CUSTOMER_REQUEST, this.selectedCustomer)
        .then(() => {
          this.updateCustomerSuccess = true;
          this.$toasted.success(this.$t('notifications.saved'), { duration: 2400 });

          this.$store
            .dispatch(GET_CUSTOMERSWITHTENANTCONNECTION_REQUEST)
            .then((resp) => {
              this.customers = resp.data;
            });

          this.$store.dispatch(GET_CUSTOMERS_REQUEST);
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
        })
        .finally(() => {
          this.updateInProgress = false;
        });
    },
    resetCustomerPassword() {
      this.resetInProgress = true;
      this.$store
        .dispatch(RESET_PASSWORD_REQUEST, this.selectedCustomerId)
        .then((resp) => {
          this.newPassword = resp.data.password;
          this.resetPasswordSuccess = true;
          this.$toasted.success(this.$t('notifications.passwordWasReset'), { duration: 2400 });
        }).catch((err) => {
          console.log(err);
          this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
        })
        .finally(() => {
          this.resetInProgress = false;
        });
    },
    getNewPassword() {
      return this.newPassword;
    },
    deleteCustomer() {
      // eslint-disable-next-line no-alert, no-restricted-globals
      if (confirm(this.$t('notifications.deleteCustomerConfirmation'))) {
        this.deleteInProgress = true;

        this.$store
          .dispatch(DELETE_CUSTOMER_REQUEST, this.selectedCustomerId)
          .then(() => {
            this.deleteCustomerSuccess = true;
            this.$toasted.success(this.$t('notifications.customerDeleted'), { duration: 1200 });

            this.$store
              .dispatch(GET_CUSTOMERSWITHTENANTCONNECTION_REQUEST)
              .then((resp) => {
                this.customers = resp.data;
                this.selectedCustomerId = null;
              });

            this.$store.dispatch(GET_CUSTOMERS_REQUEST);
            this.$store.dispatch(GET_ENVIRONMENTS_REQUEST, this.$route.params.custId);
          })
          .catch((err) => {
            console.log(err);
            this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
          })
          .finally(() => {
            this.deleteInProgress = false;
          });
      }

      return false;
    },
    checkTrialEnd(e) {
      this.$nextTick(() => {
        if (this.selectedCustomer.isTrialUser === false) {
          this.$modal.show('trial-hint');
        }
      });

      this.isTrialUser = e.target.checked;
    },
    copySuccessHandler() {
      this.showSuccessToast(this.$t('notifications.copyLinkSuccess'));
    },
    copyPasswordSuccessHandler() {
      this.showSuccessToast(this.$t('notifications.passwordCopied'));
    },
    showSuccessToast(message) {
      this.$toasted.success(message, { duration: 1200 });
    },

  },
};
</script>

<style lang="scss" scoped>
#customerList {
  tbody>tr {
    cursor: pointer;

    &:hover,
    &.selected {
      background-color: rgba($color: #2c3e50, $alpha: 0.4) !important;
    }

    &.inactive {
      background-color: #ededed;
      // color: darkgray;
    }
  }
}

#customerDetails {

  a {
    color: inherit;
  }

  .copyLink {
    cursor: pointer;
  }
}
</style>
