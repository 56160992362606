<template>
  <div v-if="dashboardData">
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <h3>
          {{ $t("general.dashboard") }}
        </h3>
        <small>{{ customerName }} - {{ environmentName }} - {{ getFlowCount() }} Workflows</small>
      </div>
      <div class="col-md-4 col-sm-12 text-center">
        <small class="fw-bold">
          {{ $t("dashboard.succesfulLastRuns") }}
        </small>
        <template>
          <vue-gauge :key="dashboardData.successfulRunPercentage" :refid="'flow-run-gauge'"
            :options="flowRunGaugeOptions"></vue-gauge>
        </template>
      </div>
    </div>

    <h5>
      {{ $t("general.lastRuns") }}
      <span class="refreshIcon ms-2" @click="refreshDashboard()" v-title="$t('general.update')">
        <font-awesome-icon icon="sync-alt" class="fa-small" :class="{ 'fa-spin': refreshInProgress }" />
      </span>
    </h5>
    <div class="form-check form-switch smallerText">
      <input class="form-check-input" type="checkbox" id="onlyFailedRunsSwitched" @click="runSwitchClicked">
      <label class="form-check-label" for="onlyFailedRunsSwitched" @click="runSwitchClicked">
        {{ $t("dashboard.onlyFailedRuns") }}
      </label>
    </div>
    <div id="lastRunContainer" class="table-responsive">
      <el-table lazy :data="dashboardData.lastRuns" :default-sort="{ prop: 'startedOn', order: 'descending' }"
        :row-class-name="rowClassName" style="width:100%;">
        <div slot="empty">{{ $t("notifications.noFlowRunsFound") }}</div>
        <el-table-column prop="flowRun.flowId" label="Flow">
          <template slot-scope="scope">
            <span class="cursor-pointer" @click="onFlowSelected(scope.row.flowId)">{{
              getFlowName(scope.row.flowId)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="endedOn" :label="$t('general.endedOn')" width="180" sortable>
          <template slot-scope="scope">
            {{ scope.row.endedOn | dateTime }}
          </template>
        </el-table-column>
        <el-table-column prop="duration" :label="$t('general.duration')" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.status === Constants.FLOW_RUN_RUNNING">Running</span>
            <span v-else>{{ scope.row.duration | duration }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('general.result')" width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.status === Constants.FLOW_RUN_SUCCEEDED" class="ps-3">
              <span class="resultIcon success">
                <font-awesome-icon icon="check-circle" />
              </span>
            </div>
            <div v-else-if="scope.row.status === Constants.FLOW_RUN_RUNNING" class="ps-3">
              <span class="resultIcon running">
                <font-awesome-icon icon="spinner" />
              </span>
            </div>
            <div v-else class="ps-3">
              <span class="resultIcon failed"
                v-title="scope.row.remediation ? scope.row.remediation.errorSubject : $t('notifications.reasonUnknown')"
                title-placement="top-end">
                <font-awesome-icon icon="times-circle" />
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('general.link')" width="80">
          <template slot-scope="scope">
            <span class="copyLink" @click="copyFlowRunLink(scope.row.flowId, scope.row.id)">
              <font-awesome-icon icon="clipboard" />
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import VueGauge from 'vue-gauge';
import { AUTH_REQUEST } from '../store/actions/auth';
import { GET_DASHBOARD_REQUEST } from '../store/actions/dashboard';
import { Constants } from '../shared/constants';

export default {
  name: 'Dashboard',
  components: { VueGauge },
  created() {
    if (this.$route.params.custId && this.$route.params.envId) {
      this.$store.dispatch(GET_DASHBOARD_REQUEST,
        {
          customerId: this.$route.params.custId,
          environmentId: this.$route.params.envId,
        });
    }
  },
  data() {
    return {
      refreshInProgress: false,
      Constants,
      onlyFailedRuns: false,
    };
  },
  computed: {
    flows() {
      const array = this.$store.state.flow.flows;
      if (array == null) return array;

      // eslint-disable-next-line no-nested-ternary
      const sorted = array.slice().sort((a, b) => ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 0)));
      return sorted;
    },
    filteredFlows() {
      return this.flows ? this.flows.filter((flow) => flow.name.toLowerCase().includes(this.search.toLowerCase())) : this.flows;
    },
    dashboardData() {
      return this.$store.state.dashboard.data;
    },
    customerName() {
      const array = this.$store.state.customer.customers;
      if (array == null) return array;

      return array.filter((customer) => customer.id === this.$route.params.custId)[0].tenantId;
    },
    environmentName() {
      const array = this.$store.state.customer.environments;
      if (array == null) return array;

      return array.filter((env) => env.id === this.$route.params.envId)[0].displayName;
    },
    flowId: {
      get() {
        return this.selectedFlowId;
      },
      set(updatedValue) {
        return updatedValue;
      },
    },
    flowRunGaugeOptions() {
      let arcDelimiter = this.dashboardData.successfulRunPercentage;
      if (arcDelimiter <= 0) arcDelimiter = 0.001;
      if (arcDelimiter >= 100) arcDelimiter = 99.999;

      return {
        needleValue: this.dashboardData.successfulRunPercentage,
        arcDelimiters: [arcDelimiter],
        centralLabel: `${this.dashboardData.successfulRunPercentage} %`,
        arcColors: ['rgba(0, 128, 0, 0.5)', 'rgba(139, 0, 0, 0.5)'],
        rangeLabel: ['0 %', '100 %'],
        chartWidth: '200',
        needleColor: '#747474',
        labelsFont: '"Saira Semi Condensed", sans-serif',
      };
    },
  },
  methods: {
    rowClassName({ row }) {
      // eslint-disable-next-line no-nested-ternary
      return row.status === Constants.FLOW_RUN_SUCCEEDED ? 'success' : (row.status === Constants.FLOW_RUN_RUNNING ? 'running' : 'failed');
    },
    onFlowSelected(flowId) {
      this.$router.push({ name: 'ListFlowRuns', params: { custId: this.$route.params.custId, envId: this.$route.params.envId, flowId } })
        .then(() => { this.scrollToElement(); })
        .catch(() => { });
    },
    scrollToElement() {
      const el = this.$refs.FlowDisplay;

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    getFlowName(flowId) {
      const flow = this.flows.filter((f) => f.id === flowId)[0];
      return flow ? flow.name : this.$t('general.unkown');
    },
    getFlowCount() {
      return this.$store.state.flow.flows ? this.$store.state.flow.flows.length : 0;
    },
    getFlowRunLink(flowId, flowRunId) {
      const { environments } = this.$store.state.customer;
      if (!environments) return environments;

      const environment = environments.filter((env) => env.id === this.$route.params.envId)[0];
      // eslint-disable-next-line max-len
      return `https://flow.microsoft.com/manage/environments/${environment.name}/flows/${flowId}/runs/${flowRunId}`;
    },
    copyFlowRunLink(flowId, flowRunId) {
      this.$clipboard(this.getFlowRunLink(flowId, flowRunId));
      this.copySuccessHandler();
    },
    copySuccessHandler() {
      this.$toasted.success(this.$t('notifications.copyLinkSuccess'), { duration: 1200 });
    },
    refreshDashboard() {
      if (!this.refreshInProgress) {
        this.refreshInProgress = true;

        this.$store.dispatch(GET_DASHBOARD_REQUEST,
          {
            customerId: this.$route.params.custId,
            environmentId: this.$route.params.envId,
            onlyFailedRuns: this.onlyFailedRuns,
          })
          .then(() => {
            this.$toasted.success(this.$t('notifications.updated'), { duration: 1200 });
          })
          .catch(() => {
            this.$toasted.error(this.$t('notifications.genericError'), { duration: 2400 });
          })
          .finally(() => {
            this.refreshInProgress = false;
          });
      }
    },
    runSwitchClicked() {
      this.onlyFailedRuns = !this.onlyFailedRuns;
      this.refreshDashboard();
    },
    aquireApiToken() {
      return new Promise((resolve, reject) => {
        if (!this.$store.getters.hasApiKey) {
          this.apiKeyRequest = setInterval(() => {
            if (this.$msal.data.idToken !== '') {
              this.$store
                .dispatch(AUTH_REQUEST, this.$msal.data.idToken)
                .then(() => {
                  console.info('api token aquired');
                  resolve();
                })
                .catch((err) => {
                  console.info('api token not aquired');
                  console.error(err);
                  reject();
                })
                .finally(() => {
                  clearInterval(this.apiKeyRequest);
                });
            }
          }, 500);
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.el-table {
  .el-table__cell {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .el-table__row {
    &.success {
      background-color: rgba($color: #46a218, $alpha: 0.1);
    }

    &.failed {
      background-color: rgba($color: #761133, $alpha: 0.1);
    }
  }
}
</style>

<style lang="scss" scoped>
h1 {
  margin: 50px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0;
}

aside {
  background-color: #a21846;
  z-index: 999;
}

.ps {
  height: calc(100vh - 220px);

  #flowContainer {

    li {
      cursor: pointer;
      overflow-wrap: break-word;
      padding: 6px 6px;
      border-bottom: 1px solid #FFF;
      width: 100%;

      &.selected {
        background-color: #b81b50;
      }

      &:hover {
        background: #5f0e29;
        color: whitesmoke;
      }
    }
  }
}

.dashboard-home {
  cursor: pointer;
}

.copyLink {
  cursor: pointer;
}

.scrollbar-y {
  overflow-y: scroll;
}

.resultIcon {
  &.success {
    color: darkgreen;
  }

  &.failed {
    color: darkred;
  }
}

.bigText {
  font-size: 3em;
}

.smallerText {
  font-size: 0.8em;
}

#lastRunContainer {
  tr {
    .cursor-pointer {
      cursor: pointer;
    }

    &.success {
      background-color: rgba($color: #46a218, $alpha: 0.1);
    }

    &.failed {
      background-color: rgba($color: #761133, $alpha: 0.2);
    }
  }
}
</style>
