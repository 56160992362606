/* eslint-disable no-shadow */
import {
  CLEAR_FLOWS_REQUEST,
  CLEAR_FLOW_RUNS_REQUEST,
  GET_FLOWS_REQUEST,
  GET_FLOWS_SUCCESS,
  GET_FLOWS_ERROR,
  GET_FLOW_REQUEST,
  GET_FLOW_SUCCESS,
  GET_FLOW_ERROR,
  GET_FLOW_RUNS_REQUEST,
  GET_FLOW_RUNS_SUCCESS,
  GET_FLOW_RUNS_ERROR,
  GET_FLOW_RUN_REQUEST,
  GET_FLOW_RUN_SUCCESS,
  GET_FLOW_RUN_ERROR,
} from '../actions/flow';
import apiService from '../../services/flowService';

const state = {
  status: '',
  flows: null,
  flowRuns: null,
  alerts: null,
};

const actions = {
  [CLEAR_FLOWS_REQUEST]: ({ commit }) => {
    commit(CLEAR_FLOWS_REQUEST);
  },

  [CLEAR_FLOW_RUNS_REQUEST]: ({ commit }) => {
    commit(CLEAR_FLOW_RUNS_REQUEST);
  },

  [GET_FLOWS_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(GET_FLOWS_REQUEST);
    apiService.getFlows(data.customerId, data.environmentId)
      .then((resp) => {
        commit(GET_FLOWS_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        commit(GET_FLOWS_ERROR);
        reject(err);
      });
  }),

  [GET_FLOW_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(GET_FLOW_REQUEST);

    apiService.getFlow(data.customerId, data.environmentId, data.flowId)
      .then((resp) => {
        commit(GET_FLOW_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        commit(GET_FLOW_ERROR);
        reject(err);
      });
  }),

  [GET_FLOW_RUNS_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(GET_FLOW_RUNS_REQUEST);
    apiService.getFlowRuns(data.customerId, data.environmentId, data.flowId, data.page)
      .then((resp) => {
        commit(GET_FLOW_RUNS_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        commit(GET_FLOW_RUNS_ERROR);
        reject(err);
      });
  }),

  [GET_FLOW_RUN_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(GET_FLOW_RUN_REQUEST);

    apiService.getFlowRun(data.customerId, data.environmentId, data.flowId, data.flowRunId)
      .then((resp) => {
        commit(GET_FLOW_RUN_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        commit(GET_FLOW_RUN_ERROR);
        reject(err);
      });
  }),
};

const mutations = {
  [CLEAR_FLOWS_REQUEST]: (state) => {
    state.flows = null;
    state.flowRuns = null;
  },
  [CLEAR_FLOW_RUNS_REQUEST]: (state) => {
    state.flowRuns = null;
  },
  [GET_FLOWS_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_FLOWS_SUCCESS]: (state, response) => {
    state.status = 'success';
    state.flows = response.data;
  },
  [GET_FLOWS_ERROR]: (state) => {
    state.status = 'error';
  },

  [GET_FLOW_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_FLOW_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [GET_FLOW_ERROR]: (state) => {
    state.status = 'error';
  },

  [GET_FLOW_RUNS_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_FLOW_RUNS_SUCCESS]: (state, resp) => {
    state.status = 'success';
    state.flowRuns = resp.data;
  },
  [GET_FLOW_RUNS_ERROR]: (state) => {
    state.status = 'error';
  },

  [GET_FLOW_RUN_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_FLOW_RUN_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [GET_FLOW_RUN_ERROR]: (state) => {
    state.status = 'error';
  },
};

export default {
  state,
  actions,
  mutations,
};
