/* eslint-disable no-shadow */
import {
  CLEAR_DASHBOARD_REQUEST,
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,
} from '../actions/dashboard';
import apiService from '../../services/dashboardService';

const state = {
  status: '',
  data: null,
};

const mutations = {
  [CLEAR_DASHBOARD_REQUEST]: (state) => {
    state.data = null;
  },
  [GET_DASHBOARD_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [GET_DASHBOARD_SUCCESS]: (state, response) => {
    state.status = 'success';
    state.data = response.data;
  },
  [GET_DASHBOARD_ERROR]: (state) => {
    state.status = 'error';
  },
};

const actions = {
  [CLEAR_DASHBOARD_REQUEST]: ({ commit }) => {
    commit(CLEAR_DASHBOARD_REQUEST);
  },
  [GET_DASHBOARD_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_DASHBOARD_REQUEST);
    apiService.getDashboard(params.customerId, params.environmentId, params.onlyFailedRuns)
      .then((response) => {
        commit(GET_DASHBOARD_SUCCESS, response);
        resolve(response);
      })
      .catch((err) => {
        commit(GET_DASHBOARD_ERROR);
        reject(err);
      });
  }),
};

export default {
  state,
  actions,
  mutations,
};
