import Vue from 'vue';
import Vuex from 'vuex';
import customer from './modules/customer';
import auth from './modules/auth';
import flow from './modules/flow';
import alert from './modules/alert';
import dashboard from './modules/dashboard';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    customer,
    auth,
    flow,
    dashboard,
    alert,
  },
  strict: debug,
});
