import axios from 'axios';
import serviceconfig from './serviceConfig';

export default {
  async getFlows(customerId, environmentId) {
    return axios.get(`${serviceconfig.apiBase}/customers/${customerId}/environments/${environmentId}/flows`);
  },

  async getFlow(customerId, environmentId, flowId) {
    return axios.get(`${serviceconfig.apiBase}/customers/${customerId}/environments/${environmentId}/flows/${flowId}`);
  },

  async getFlowRuns(customerId, environmentId, flowId, page) {
    return axios.get(`${serviceconfig.apiBase}/customers/${customerId}/environments/${environmentId}/flows/${flowId}/flowRuns?page=${page}`);
  },

  async getFlowRun(customerId, environmentId, flowId, flowRunId) {
    return axios.get(`${serviceconfig.apiBase}/customers/${customerId}/environments/${environmentId}/flows/${flowId}/flowRuns/${flowRunId}`);
  },
};
