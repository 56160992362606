<template>
  <modal name="add-alert" transition="pop-out" :width="modalWidth" :focus-trap="true" :height="400" @closed="closed">
    <div class="box">
      <div class="partition" id="partition-register">
          <div class="partition-title">{{ this.alertScope == 'Environment' ? $t("alertModal.addEnvironmentAlert") : $t("alertModal.addFlowAlert") }}</div>
          <div class="partition-form">
              <form autocomplete="false" name="addAlertForm" v-on:submit.prevent="addAlert">
                  <label for="n-type">{{ $t("flow.alertType") }}</label>
                  <input id="n-type" type="text" autocomplete="false" disabled v-model="alertType">

                  <label for="n-result">{{ $t("flow.alertCondition") }}</label>
                  <select required id="n-result" class="form-select" v-model="resultType">
                      <option value="Failed" selected>{{ $t("alertModal.alertTypeFailed") }}</option>
                      <option value="Succeeded">{{ $t("alertModal.alertTypeSucceeded") }}</option>
                  </select>

                  <label for="n-target">{{ $t("flow.alertTarget") }}</label>
                  <input required id="n-target" type="email" placeholder="Email" autocomplete="false" v-model="alertTarget">
                  <small>{{ $t("alertModal.teamsEmailBottomHint1") }}
                      <a href="https://support.microsoft.com/de-de/office/tipp-senden-einer-e-mail-an-einen-kanal-2c17dbae-acdf-4209-a761-b463bdaaa4ca" target="_blank">
                      {{ $t("alertModal.teamsEmailBottomHint2") }}</a></small>

                  <div class="button-set text-end mt-2">
                      <button class="btn-fake" type="submit" id="save-btn" :disabled="inProgress">{{ $t("general.add") }}</button>
                      <div class="btn-fake" id="cancel-btn" @click="cancel">{{ $t("general.cancel") }}</div>
                  </div>
              </form>
          </div>
      </div>
    </div>
  </modal>
</template>

<script>
import {
  ADD_ENVIRONMENT_ALERT_REQUEST,
  ADD_FLOW_ALERT_REQUEST,
} from '../store/actions/alert';
import { Constants } from '../shared/constants';

const MODAL_WIDTH = 656;

export default {
  name: 'AddAlertModal',
  props: {
    alertScope: String,
    onClose: Function,
  },
  data() {
    return {
      modalWidth: MODAL_WIDTH,
      alertType: Constants.ALERT_TYPE_EMAIL,
      alertTarget: '',
      resultType: '',
      inProgress: false,
    };
  },
  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
  methods: {
    cancel() {
      this.$modal.hide('add-alert');
      this.alertTarget = '';
      this.resultType = '';
    },
    closed() {
      this.onClose();
    },
    dontSend(e) {
      e.preventDefault();
    },
    addAlert() {
      this.inProgress = true;
      if (this.$route.params.custId && this.$route.params.envId) {
        this.$store.dispatch(this.alertScope === Constants.ALERT_SCOPE_FLOW ? ADD_FLOW_ALERT_REQUEST : ADD_ENVIRONMENT_ALERT_REQUEST, {
          customerId: this.$route.params.custId,
          environmentId: this.$route.params.envId,
          flowId: this.$route.params.flowId,
          body: {
            alertType: this.alertType,
            userId: this.$msal.data.user.userName,
            alertResultType: this.resultType,
            alertTarget: this.alertTarget,
            alertScope: this.alertScope,
          },
        })
          .then(() => {
            this.$toasted.success(this.$t('notifications.addAlertSuccess'), { duration: 1200 });
          })
          .catch(() => {
            this.$toasted.error(this.$t('notifications.addAlertError'), { duration: 2400 });
          })
          .finally(() => {
            this.alertTarget = '';
            this.resultType = '';
            this.inProgress = false;
            this.$modal.hide('add-alert');
          });
      }
    },
  },
};
</script>
<style lang="scss">
$background_color: #404142;

.box {
  background: white;
  overflow: hidden;
  height: 400px;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 0 40px black;
  color: #8b8c8d;
  font-size: 0;

  .partition {
    width: 100%;
    height: 100%;

    .partition-title {
      box-sizing: border-box;
      padding: 30px;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 20px;
      font-weight: 300;
    }

    .partition-form {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }

  label, small {
    display: block;
    box-sizing: border-box;
    margin-bottom: 2px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    border: 0;
    padding: 2px 8px;
    font-family: inherit;
    transition: 0.5s all;
  }

  small {
      font-size: 10px;
      margin: 0;
      padding:0;
      line-height:1;
  }

  input[type='text'],
  input[type='email'],
  select {
    display: block;
    box-sizing: border-box;
    margin-bottom: 16px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    border: 0;
    border-bottom: 1px solid #dddedf;
    padding: 4px 8px;
    font-family: inherit;
    transition: 0.5s all;
  }

  .btn-fake {
    background: white;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    min-width: 140px;
    width: 140px;
    text-align: center;
    margin-top: 8px;
    display: inline-block;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: 0.1s all;
    font-size: 10px;
    &:hover {
      border-color: mix(#dddedf, black, 90%);
      color: mix(#8b8c8d, black, 80%);
    }
  }

  .large-btn {
    width: 100%;
    background: white;

    span {
      font-weight: 600;
    }
    &:hover {
      color: white !important;
    }
  }

  #cancel-btn,
  #save-btn {
    margin-left: 8px;
  }
}

.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.5s;
}

.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}
</style>
